<template>
    <div>
        <modal :name="modal_name" transition="nice-modal-fade" class="final-modal">
            <div class="v-modal-content">
                <div class="v-modal-header align-items-center">
                    <span class="v-modal-dialog-title v-modal-title">VIEW COMBO</span>
                    <span class="pull-right" style="cursor:pointer;" @click="cancel()">
                        <i class="icon icon-close" style="font-size: 22px!important; color: #5d5b5b;"></i>
                    </span>
                </div>
                <div class="v-modal-body pr-0">
                    <div class="v-modal-layout pr-3" style="padding-right: 18px;max-height: 430px !important; overflow-x: hidden !important;overflow-y: scroll !important">
                        <div class="row justify-content-end mr-1 mb-2">
                            <button class="btn btn-secondary btn-smm" @click="editCombo(viewDetails._id)">
                                <i class="icon icon-pencil fs-16"></i>
                                Edit
                            </button>
                        </div>
                        <div class="section">
                            <p class="text-secondary font-600 pl-4">
                                General Section :
                            </p>
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Combo Name
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView">
                                        <span class="a-q-Jc-wg-contactView">
                                            {{viewDetails.name}}
                                        </span>
                                    </div>
                                </div>
                            </div> 
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Category
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView" v-if="viewDetails.category_id">
                                        <span class="a-q-Jc-wg-contactView">
                                            {{viewDetails.category_id.name}}
                                        </span>
                                    </div>
                                </div>
                            </div> 
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Sub Category
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView" v-if="viewDetails.sub_category_id">
                                        <span class="a-q-Jc-wg-contactView">
                                            {{viewDetails.sub_category_id.name}}
                                        </span>
                                    </div>
                                </div>
                            </div> 
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Product Type
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView" v-if="viewDetails.product_type_id">
                                        <span class="a-q-Jc-wg-contactView">
                                            {{viewDetails.product_type_id.name}}
                                        </span>
                                    </div>
                                </div>
                            </div> 
                        </div>
                        <div class="section mt-5">
                            <p class="text-secondary font-600 pl-3">
                                Combo Section :
                            </p>
                            <div class="tablebg" v-if="viewDetails.combo_items">
                                <table class="table">
                                    <thead>
                                        <tr style="height:30px !important">
                                            <th>Products</th>
                                            <th>Variant</th>
                                            <th>Quantity</th>
                                            <th>Price</th>
                                            <th>Discount</th>
                                            <th>Effective Price <br> after discount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style="height:30px !important" v-for="(co,index) in viewDetails.combo_items" :key="index">
                                            <td>{{co.product_id.name}}</td>
                                            <td>{{co.variant_id.variant_name || "-"}}</td>
                                            <td>{{co.quantity}}</td>
                                            <td><span v-if="viewDetails.outlet_currency">{{viewDetails.outlet_currency.symbol}}</span>{{co.total_price}}</td>
                                            <td><span v-if="viewDetails.outlet_currency">{{viewDetails.outlet_currency.symbol}}</span>{{co.discount}}</td>
                                            <td><span v-if="viewDetails.outlet_currency">{{viewDetails.outlet_currency.symbol}}</span>{{co.price_after_discount}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="section mt-5">
                            <p class="text-secondary font-600 pl-4">
                                Discount Section :
                            </p>
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Apply Discount To
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView">
                                        <span class="a-q-Jc-wg-contactView" v-for="(d,i) in viewDetails.discount_breakdown" :key="i">
                                            {{d.category_id.name}}
                                            <span class="font-weight-bold" v-if="i != viewDetails.discount_breakdown.length - 1">/</span>
                                        </span>
                                    </div>
                                </div>
                            </div> 
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Discount In
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView" v-if="viewDetails.discount">
                                        <span class="a-q-Jc-wg-contactView" style="text-transform:capitalize;">
                                            {{viewDetails.discount.type}}
                                        </span>
                                    </div>
                                </div>
                            </div> 
                            <div class="a-q-Jc-j-contactView" v-for="(d,i) in viewDetails.discount_breakdown" :key="i">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        {{d.category_id.name}} Total Discount
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView">
                                        <span class="a-q-Jc-wg-contactView">
                                            <span v-if="viewDetails.outlet_currency">{{viewDetails.outlet_currency.symbol}}</span>{{d.amount}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="section mt-5">
                            <p class="text-secondary font-600 pl-4">
                                Calculation Section :
                            </p>
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Total Price
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView">
                                        <span class="a-q-Jc-wg-contactView">
                                            <span v-if="viewDetails.outlet_currency">{{viewDetails.outlet_currency.symbol}}</span>{{viewDetails.total_combo_price}}
                                        </span>
                                    </div>
                                </div>
                            </div> 
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Discount In
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView" v-if="viewDetails.discount">
                                        <span class="a-q-Jc-wg-contactView" style="text-transform:capitalize;">
                                            <span v-if="viewDetails.outlet_currency">{{viewDetails.outlet_currency.symbol}}</span>{{viewDetails.discount.type}}
                                        </span>
                                    </div>
                                </div>
                            </div> 
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Discount Amount
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView" v-if="viewDetails.discount">
                                        <span class="a-q-Jc-wg-contactView">
                                            <span v-if="viewDetails.outlet_currency">{{viewDetails.outlet_currency.symbol}}</span>{{viewDetails.discount.value}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="a-q-Jc-j-contactView">
                                <div class="a-q-Jc-Pj-Ya-contactView">
                                    <div class="a-q-Jc-bj-contactView">
                                        Combo Price
                                    </div>
                                    <div class="a-q-Jc-Lb-contactView">
                                        <span class="a-q-Jc-wg-contactView">
                                            <span v-if="viewDetails.outlet_currency">{{viewDetails.outlet_currency.symbol}}</span>{{viewDetails.price_after_discount}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="v-modal-dialog-actions modal-bg text-center pt-5">
                    <a class="btn btn-secondary btn-smm" @click="cancel">Close</a>
                </div>
            </div>
        </modal>
        <combo-edit modal_name="edit-combo" :source="'viewCombo'" @cancelviewEditsaved="cancelviewEditsaved()" @cancelviewEdit="cancelviewEdit()"></combo-edit>
    </div>
</template>
<script>
import { EventBus } from './eventBus/nav-bar-event'
const ComboEdit = () => import('./EditCombo')
export default {
    props:['modal_name'],
    data(){
        return{
            viewDetails:{

            },
            source:'',
            edited:false
        }
    },
    components:{
        ComboEdit
    },
    methods:{
        cancel(){
            this.viewDetails = {}
            if(this.edited){
                this.$emit('saved')
            }
            this.$modal.hide(this.modal_name)
            this.edited = false
        },
        cancelviewEdit(){
           this.$modal.show(this.modal_name) 
        },
        cancelviewEditsaved(){
            this.$modal.show(this.modal_name) 
            this.getDetails()
            this.edited = true
        },
        editCombo(id){
            let params ={
                _id:id
            }
            this.$modal.hide(this.modal_name)
            EventBus.$emit('getEditData',params)
            this.$modal.show('edit-combo')
        },
        async getDetails(){
            try {
                let params = {
                    _id:this.viewDetails._id
                } 
                let res = await this.$http.post('/combos/get_combo_by_id',params)
                if(res.data.status_id == 1){
                    this.viewDetails = res.data.combo
                }
            } catch (reason) {
                
            }
        }
    },
    mounted(){
        EventBus.$on('viewcombo',params=>{
            this.$http.post('/combos/get_combo_by_id',params).then(res=>{
                if(res.data.status_id == 1){
                    this.viewDetails = res.data.combo
                }
            })
        })
    }
}
</script>
<style scoped>
.section {
    padding: 20px !important;
    background: #fff !important;
    border-radius: 12px !important;
}
.a-q-Jc-Pj-Ya-contactView {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    line-height: 30px;
}

.a-q-Jc-Pj-Ya-contactView {
    line-height: 25px;
    padding: 10px 10px 10px 3px;
    border-bottom: solid 1px #9e9e9e69;
}

.a-q-Jc-bj-contactView {
    -webkit-flex: 0 0 190px;
    flex: 0 0 190px;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #303031;
    margin-left: 13px;
}

.a-q-Jc-Lb-contactView {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    text-decoration: none;
    line-height: 1.21;
    letter-spacing: normal;
    color: #303031;
    margin-left: 88px;
}

.a-q-Jc-Pj-Ya-contactView:nth-of-type(even) {
    background-color: #f5f6f9;
}

.a-q-Jc-Lb-contactView.linkactive {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: right;
    color: #00448b;
}

.a-q-Jc-wg-contactView {
    text-decoration: none;
}
.badgenew{
    padding: 5px 10px !important;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 8px;
    color: #fff !important;
}
.bg-greennew{
    background-color: #4CAF50 !important;
}
.border-bg{
    background-color: #fff !important;
    padding: 8px !important;
    border-radius: 12px !important;
    margin-bottom: 8px !important;
}
.back-blue{
    background-color: #00448b !important;
    border-radius: 12px !important;
}
.borderBottom{
    border-bottom: 1px solid #fff;
}
.font-500{
    font-weight: 500 !important;
}
.tablebg{
    background: #dee8f2 !important;
    border-radius: 18px;
}
</style>